module.exports = {
  language: {
    name: '中文'
  },

  RecruitApplyForm: {
    name: '幼儿名',
    nickName: '英文名/昵称',
    gender: '性别',
    birth: '出生日期',
    country: '国籍',
    enrollmentYear: '入学时间',
    gradeToApply: '申请年级',
    howKnowSchool: '您是通过何种渠道知道我们学校的',
    guardianRelation: '与申请者的关系',
    guardianName: '联系人姓名',
    guardianContact: '联系电话',
    guardianEmail: "邮箱",
    btnSubmit: '递交',
    male: '男',
    female: '女',
    selectAnOption: '选择一个选项',
    enrollmentTimeOption1: '尽快',
    enrollmentTimeOption2: '其他',

    confirmErrorTip1: '此字段是必须的，不能留空',
    confirmErrorTip2: '请输入正确的联系方式',
    confirmErrorTip3: '电子邮件格式无效',

    applicationHasBeenSubmitted: '申请已提交',
    viewApplys: '查看我的申请',
    addNewApply: '继续申请',

    schoolAddr: '上海市黄浦区江阴路57号（靠近黄陂北路上海大剧院）',
  },

  AboutUsSource: [
    { value: 'FAMILY', label: '家人/亲属' },
    { value: 'FRIEND', label: '朋友/同事' },
    { value: 'INTERNET', label: '互联网搜索' },
    { value: 'ADVERTISEMENT', label: '广告' },
    { value: 'AGENCY', label: '房产中介' },
    { value: 'OTHER', label: '其他' }
  ],

  Calendar: {
    weeks: ['日', '一', '二', '三', '四', '五', '六']
  }
};
