Function.prototype.method = function (name, func) {
  //避免覆盖基类的原生方法
  if (!this.prototype[name]) {
    this.prototype[name] = func;
    return this;
  }
};

function isValidDate(date) {
  return date instanceof Date && !isNaN(date.getTime());
}

function formatDateTime(date, format) {
  const o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "h+": date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
    "H+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
    a: date.getHours() < 12 ? "上午" : "下午", // 上午/下午
    A: date.getHours() < 12 ? "AM" : "PM", // AM/PM
  };
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
}

Number.method('toDate', function () {
  if (!isNaN(this) && this > 0) {
    return new Date(this);
  }
  return null;
});

Number.method('dateFormat', function (fmt = 'yyyy-MM-dd HH:mm:ss') {

  let date = this.toDate();
  if (isValidDate(date)) {
    return formatDateTime(date, fmt);
  }
  return '';
});

String.method('toDate', function (fmt = 'yyyy-MM-dd HH:mm:ss') {

  if ((this || '').trim().length > 0) {
    let val = (this || '').trim().replace('/', '-');
    return new Date(val);
  }
  return null;
});

String.method('dateFormat', function (fmt = 'yyyy-MM-dd HH:mm:ss') {
  let date = this.toDate();
  if (isValidDate(date)) {
    return formatDateTime(date, fmt);
  }
  return '';
});


Date.method('format', function (fmt = 'yyyy-MM-dd HH:mm:ss') {
  if (isValidDate(this)) {
    return formatDateTime(this, fmt);
  }
  return '';
});

