module.exports = {
    language: {
        name: 'English'
    },

    RecruitApplyForm: {
        name: 'Student Name',
        nickName: 'English Name/Nickname',
        gender: 'Sex',
        birth: 'Date of Birth',
        country: 'Nationality',
        enrollmentYear: 'Enrollment Time',
        gradeToApply: 'Grade to Apply',
        howKnowSchool: 'How did you learn about our school？',
        guardianRelation: 'Relationship to Applicant',
        guardianName: 'Contact Name',
        guardianContact: 'Contact Number',
        guardianEmail: "Email",
        btnSubmit: 'Submit',
        male: 'Male',
        female: 'Female',
        selectAnOption: 'Select an Option',
        enrollmentTimeOption1: 'Now',
        enrollmentTimeOption2: 'Other',

        confirmErrorTip1: 'This field is Required and cannot be left blank.',
        confirmErrorTip2: 'Contact Number has invalid phone format',
        confirmErrorTip3: 'Email has invalid email format',

        applicationHasBeenSubmitted: 'Application has been submitted',
        viewApplys: 'View My Application',
        addNewApply: 'Create a new application',

        schoolAddr: 'No. 57, Jiang Yin Road, (Nearby Shanghai Theatre)',
    },

    AboutUsSource: [
        { value: 'FAMILY', label: 'Family Famille' },
        { value: 'FRIEND', label: 'Friends/Colleagues Amis/collègues' },
        { value: 'INTERNET', label: 'Internet Search Recherche Internet' },
        { value: 'ADVERTISEMENT', label: 'Advertisement Publicité' },
        { value: 'AGENCY', label: 'Relocation Agency Agence de relocalisation' },
        { value: 'OTHER', label: 'Other Autre' }
    ]
}