import { tableData } from "./table-mock-data";

import Cookies from "js-cookie";
// 加密解密
let Base64 = require("js-base64").Base64;

const TaskStatusMap = {
  0: "todo",
  1: "progress",
  2: "completed",
  "-1": "overdue",
};

export const StudentFilterMenus = [
  { key: "reports", title: "问卷筛选" },
  { key: "clazz", title: "年级班级" },
  { key: "gender", title: "性别" },
  { key: "range", title: "身份" },
  { key: "country", title: "国籍" },
  { key: "age", title: "年龄段" },
  { key: "join_time", title: "入园时间" },
  // { key: 'addr', title: '家校距离' },
  { key: "tag", title: "标签/分组" },
];

export const StudentTableColumn = [
  { key: "gender", title: "性别", icon: "icon-xingbie" },
  { key: "age", title: "年龄", icon: "icon-nianling1" },
  { key: "guardian", title: "监护人", icon: "icon-lianxifangshi1" },
  { key: "addr", title: "住址", icon: "icon-juli1" },
];

export const StudentRange = [
  // { label: "本区", value: 5 },
  { label: "本市", value: 1 },
  { label: "外省市", value: 2 },
  { label: "港澳台", value: 3 },
  { label: "外籍", value: 4 },
  // { label: "其他", value: 0 },
];

export const StudentCardType = [
  { label: "身份证", value: "IDCARD" },
  { label: "护照", value: "PASSPORT" },
  { label: "港澳通行证", value: "PASS" }
];

export const ParentIdentity = [
  { value: 'DAD', label: 'Father', name: '父亲'},
	{ value: 'MOM', label: 'Mother', name: '母亲'},
	{ value: 'GF', label: 'Grandfather', name: '祖父'},
	{ value: 'GM', label: 'Grandmother', name: '祖母'},
	{ value: 'MGF', label: '(Maternal) Grandfather', name: '外祖父'},
	{ value: 'MGM', label: '(Maternal) Grandmother', name: '外祖母'},
	{ value: 'OTHER', label: 'Other', name: '其他监护人'}
];

export const AttendanceTypes = [
  { value: "HOLS_NIAN", label: "年假" },
  { value: "HOLS_DXB", label: "带薪病假" },
  { value: "HOLS_ZG", label: "主管假" },
  { value: "HOLS_GL", label: "工龄假" },
  { value: "HOLS_OTHER", label: "特殊假" },
  { value: "HOLS_TX", label: "调休" },
  { value: "HOLS_LC", label: "路程假" },
  { value: "HOLS_SHI", label: "事假" },
  { value: "HOLS_BING", label: "病假" },
  { value: "HOLS_CHAN", label: "产假" },
  { value: "HOLS_HUN", label: "婚假" },
  { value: "HOLS_SANG", label: "丧假" },
  { value: "HOLS_GONG", label: "公出" },
  { value: "ATTENDANCE_CD", label: "迟到" },
  { value: "ATTENDANCE_ZT", label: "早退" },
  { value: "ATTENDANCE_JIA", label: "加班" },
  { value: "ATTENDANCE_KG", label: "旷工" },
]
export const HasBalanceHoliays = ['HOLS_NIAN', 'HOLS_DXB', 'HOLS_ZG']


export const WorkTypes = [
  { value: "FULL_TIME", label: "全职" },
  { value: "PART_TIME", label: "兼职" },
  { value: "PROBATION", label: "试用" },
  { value: "PRACTICE", label: "实习" },
  { value: "RETIRED", label: "退休" }
]


export const EducationalTypes = [
  { value: "HIGH_SCHOOL_EDUCATION", label: "高中及以下" },
  { value: "JUNIOR_EDUCATION", label: "大学专科" },
  { value: "UNDERGRADUATE_EDUCATION", label: "大学本科" },
  { value: "MASTER_EDUCATION", label: "硕士及以上" }
]

export const SkillLevels = [
  { value: 'FLUENT', label: '流利' },
  { value: 'ORDINARY', label: '普通' },
  { value: 'SLIGHTLY', label: '略懂' },
]

// 政治面貌  CROWD#群众  PARTY#党员  GROUP#团员
// 户籍    TOWN#城镇  VILLAGE#农村
// 婚姻状况  UNMARRIED#未婚   MARRIED#已婚  DIVORCE#离异


export const PaymentTypes = [
  // COMPANY#公司 PERSONAL#个人
  { value: 'PERSONAL', label: '个人' },
  { value: 'COMPANY', label: '公司' }
]

export const PaymentMethods = [
  // YEAR#年付 MONTH#月付 SEMESTER#期付 OTHER#其他
  { value: 'MONTH', label: 'Month' },
  { value: 'SEMESTER', label: 'Semester' },
  { value: 'YEAR', label: 'Year' },
  { value: 'FREE', label: 'Free' }
]

export const StudentFeeTypes = [
  // 类型 STORE#商城 TUITION#学费 MEALS#餐费 SCBUS_FEE#校车费 OTHER#其他收费
  { value: 'TUITION', label: '学费' },
  { value: 'MEALS', label: '餐费' },
  { value: 'SCBUS_FEE', label: '校车费' },
  { value: 'OTHER', label: '其他收费' }
]

export const PaymentStatus = [
  //  <!-- 订单状态 待支付#WAITING 已支付#PAID 已关闭#CLOSE 欠费#ARREARS 申请退费#REFUND_APPLY 退费中#REFUNDING 已退费#REFUNDED -->
  { value: 'WAITING', label: '待支付' },
  { value: 'PAID', label: '已支付' },
  { value: 'CLOSE', label: '已关闭' },
  { value: 'ARREARS', label: '欠费' },
  { value: 'REFUND_APPLY', label: '申请退费' },
  { value: 'REFUNDING', label: '退费中' },
  { value: 'REFUNDED', label: '已退费' }
]

export const PaymentPaidTypes = [
  // <!--  无需支付#NONE 微信#WECHAT ALIPAY#支付宝 朋友代付#FRIEND_PAY 线下付款#OFFLINE -->
  { value: 'NONE', label: '无需支付' },
  { value: 'WECHAT', label: '微信' },
  { value: 'ALIPAY', label: '支付宝' },
  { value: 'FRIEND_PAY', label: '朋友代付' },
  { value: 'OFFLINE', label: '线下付款' }
]

export const SchoolGrades = [
  {
    value: 'GLM',
    label: 'Little Magnolia 小小班',
    start: '01-01',
    end: '12-31',
    children: [
      { value: 'LM', label: 'Little Magnolia 小小班', minAge: 0, maxAge: 1 },
    ]
  },
  {
    value: 'ENG',
    label: 'ENG英语班',
    start: '09-01',
    end: '08-31',
    children: [
      { value: 'TWOS', label: 'TWOS托班(2-3)', minAge: 2, maxAge: 3 }, 
      { value: 'THREES', label: 'THREES小班(3-4)', minAge: 3, maxAge: 4 }, 
      { value: 'PRE-K', label: 'PRE-K中班(4-5)', minAge: 4, maxAge: 5 }, 
      { value: 'K', label: 'K大班(5-6)', minAge: 5, maxAge: 6 }
    ]
  },
  {
    value: 'FR',
    label: 'FR法语班',
    start: '01-01',
    end: '12-31',
    children: [
      { value: 'TPS', label: 'TPS托班(2-3)', minAge: 2, maxAge: 2 }, 
      { value: 'PS', label: 'PS小班(3-4)', minAge: 3, maxAge: 3 }, 
      { value: 'MS', label: 'MS中班(4-5)', minAge: 4, maxAge: 4 }, 
      { value: 'GS', label: 'GS大班(5-6)', minAge: 5, maxAge: 5 }, 
      { value: 'CP', label: 'CP预备班(6-7)', minAge: 6, maxAge: 6 }
    ]
  }
]

export const AboutUsSource = [
  { value: 'FAMILY', label: '家人/亲属' },
  { value: 'FRIEND', label: '朋友/同事' },
  { value: 'INTERNET', label: '互联网搜索' },
  { value: 'ADVERTISEMENT', label: '广告' },
  { value: 'AGENCY', label: '房产中介' },
  { value: 'OTHER', label: '其他' }
]

let map2Target = (source, key, value) => {
  let target = {};
  let temp = (source || []).map((item) => {
    if (item[key] == value) {
      target = item;
      return target;
    }
  });
  return target;
};

export const sys_def = {
  tableData: tableData,
  taskStatusMap: TaskStatusMap,
  StudentRange: StudentRange,
  StudentCardType: StudentCardType,
  ParentIdentity: ParentIdentity,
  
  AttendanceTypes: AttendanceTypes,
  HasBalanceHoliays: HasBalanceHoliays,

  WorkTypes: WorkTypes,
  EducationalTypes: EducationalTypes,
  SkillLevels: SkillLevels,

  PaymentTypes: PaymentTypes,
  PaymentMethods: PaymentMethods,
  StudentFeeTypes: StudentFeeTypes,
  PaymentStatus: PaymentStatus,
  PaymentPaidTypes: PaymentPaidTypes,

  SchoolGrades: SchoolGrades,
  AboutUsSource: AboutUsSource,

  map2StudentRange(value) {
    return map2Target(StudentRange, "value", value);
  },
  map2StudentCardType(value) {
    return map2Target(StudentCardType, "value", value);
  },
  map2Attendanceype(value) {
    return map2Target(AttendanceTypes, "value", value);
  },

  map2WorkType(value) {
    return map2Target(WorkTypes, "value", value);
  },

  map(source, value, key = 'value') {

    let data = this[source] || []
    return map2Target(data, key, value);
  }
};
